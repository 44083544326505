import { BLOCKS } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { gridOffsetFrom } from 'bl-common/src/constants/sizes'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionInformationFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { theme } from 'bl-common/src/styles/theme'
import { Gradient } from 'bl-common/src/types/custom'
import { Appear } from 'bl-common/src/units/Appear'
import { Section } from 'bl-common/src/units/Section/Section'
import { mediaObj } from 'bl-common/src/utils/media'

import { formatGradient } from 'utils/formatters'

type InformationSectionProps = {
  section: {
    fields: ISectionInformationFields
  }
}

interface ContentWrapperProps {
  split?: boolean
}

export const Container = styled.div<{ gradient?: Gradient }>(
  ({ gradient }) => ({
    position: 'relative',

    ...(gradient && {
      padding: `${theme.spacing[4]} 0 ${theme.spacing[4]}`,

      '::after': {
        content: '""',
        position: 'absolute',
        inset: 0,
        left: theme.spacing[6],
        right: gridOffsetFrom * -1,
        background: `linear-gradient(
          ${gradient.degrees},
          ${gradient.color1},
          ${gradient.color2}
        );`,
        zIndex: zIndex.behind,
      },
    }),

    [mediaObj.md]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gap: theme.spacing[2.5],

      ...(gradient && {
        padding: `${theme.spacing[7.5]} 0 ${theme.spacing[4]}`,
        '::after': {
          inset: 0,
          left: theme.spacing[4.5],
        },
      }),
    },
  })
)

export const HeadingWrapper = styled.div({
  marginBottom: theme.spacing[2],
  alignSelf: 'flex-start',
  [mediaObj.md]: {
    gridColumn: '1 / span 3',
    position: 'sticky',
    left: 0,
    top: theme.spacing[4],
  },
})

export const ContentWrapper = styled.div<ContentWrapperProps>(({ split }) => ({
  [mediaObj.md]: {
    gridColumn: split ? 'span 4' : 'span 8',
  },
}))

const ListItem = styled(Type).attrs({ as: 'li' })({
  marginTop: theme.spacing[1],
  [mediaObj.md]: {
    fontSize: 16,
  },
})

const ListWrapper = styled.div({
  breakInside: 'avoid',
  '&:not(:last-child)': {
    marginBottom: theme.spacing[3],
  },
})

const contentOptions = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (_, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.UL_LIST]: (_, children) => {
      return (
        <ListWrapper>
          <ul>{children?.map(x => x)}</ul>
        </ListWrapper>
      )
    },
  },
}
const headingOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type as="h2" preset="headlineLarge">
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type as="h3" preset="headlineMedium">
        {children}
      </Type>
    ),
  },
}

const InformationSection = ({
  section: {
    fields: {
      heading,
      informationMainColumn,
      informationSecondColumn,
      gradient,
      config,
    },
  },
}: InformationSectionProps) => {
  const hasSecondColumn = !!informationSecondColumn
  const formattedGradient = formatGradient(gradient)

  return (
    <Section top={{ xs: 3, md: 8 }} bottom={{ xs: 3, md: 8 }} config={config}>
      <Appear observer>
        <Container gradient={formattedGradient}>
          <HeadingWrapper>
            <Appear delay={1}>
              <RichTextRenderer
                document={heading}
                customOptions={headingOptions}
              />
            </Appear>
          </HeadingWrapper>
          <ContentWrapper split={hasSecondColumn}>
            <Appear delay={200}>
              <RichTextRenderer
                document={informationMainColumn}
                customOptions={contentOptions}
              />
            </Appear>
          </ContentWrapper>
          {hasSecondColumn && (
            <ContentWrapper split={hasSecondColumn}>
              <Appear delay={300}>
                <RichTextRenderer
                  document={informationSecondColumn}
                  customOptions={contentOptions}
                />
              </Appear>
            </ContentWrapper>
          )}
        </Container>
      </Appear>
    </Section>
  )
}

export default InformationSection
