import { useRef } from 'react'
import { useParallax } from 'react-scroll-parallax'
import { useWindowSize } from '@react-hookz/web'
import get from 'lodash/get'
import styled from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { colors } from 'bl-common/src/constants/colors'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionVideoScrollFields } from 'bl-common/src/generated/contentful'
import { Section } from 'bl-common/src/units/Section/Section'

import { VideoHero } from '../../VideoHero'

type VideoScrollSectionProps = {
  section: {
    fields: ISectionVideoScrollFields
  }
}

type ContainerProps = {
  scrollHeight: number
}

type BorderProps = {
  scrollHeight: number
}

export const Container = styled.div<ContainerProps>(({ scrollHeight }) => ({
  width: '100%',
  position: 'relative',
  display: 'block',
  height: `${(scrollHeight ?? 3) * 100}vh`,
}))

export const StickyContainer = styled.div({
  position: 'sticky',
  top: 0,
  left: 0,
})

export const Border = styled.div<BorderProps>({
  position: 'absolute',
  zIndex: 1,
  backgroundColor: colors.white,

  '.t-dark &, .gradient-dark &': {
    backgroundColor: colors.black,
  },
})

export const HorizontalBorder = styled(Border)({
  left: 0,
  right: 0,
  height: 'calc(50% - 40px)',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    height: 'calc(50% - 144px)',
  },
})

export const VerticalBorder = styled(Border)({
  top: -1,
  bottom: -1,
  width: 'calc(50% - 128px)',

  [`@media (min-width: ${breakpoints.md}px)`]: {
    width: 'calc(50% - 256px)',
  },
})

export const TopBorder = styled(HorizontalBorder)({
  top: -1,
  transformOrigin: '0% 0%',
})

export const BottomBorder = styled(HorizontalBorder)({
  bottom: -1,
  transformOrigin: '0% 100%',
})

export const LeftBorder = styled(VerticalBorder)({
  left: 0,
  transformOrigin: '0% 0%',
})

export const RightBorder = styled(VerticalBorder)({
  right: 0,
  transformOrigin: '100% 0%',
})

export const TextWrapper = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 100,
})

const VideoScrollSection = ({
  section: {
    fields: { videoHero, scrollHeight = 3, text, textColor = 'light', config },
  },
}: VideoScrollSectionProps) => {
  const { height: windowHeight } = useWindowSize()
  const targetElement = useRef<HTMLDivElement>(null)

  const commonProps = {
    speed: 0,
    rootMargin: {
      top: -windowHeight / 4,
      bottom: -windowHeight,
      left: 0,
      right: 0,
    },
    targetElement: targetElement.current,
  }

  const { ref: topRef } = useParallax<HTMLDivElement>({
    ...commonProps,
    scaleY: [1, 0],
  })

  const { ref: bottomRef } = useParallax<HTMLDivElement>({
    ...commonProps,
    scaleY: [1, 0],
  })

  const { ref: leftRef } = useParallax<HTMLDivElement>({
    ...commonProps,
    scaleX: [1, 0],
  })

  const { ref: rightRef } = useParallax<HTMLDivElement>({
    ...commonProps,
    scaleX: [1, 0],
  })

  const { ref: textRef } = useParallax<HTMLDivElement>({
    ...commonProps,
    rootMargin: {
      top: -windowHeight / 4,
      bottom: windowHeight - scrollHeight * windowHeight - windowHeight * 0.8,
      left: 0,
      right: 0,
    },
    opacity: [0.2, 1],
  })

  const textColors = {
    light: colors.white,
    dark: colors.dark,
    blue: colors.deepBlue,
  }

  return (
    <Section config={config} top={0} bottom={{ xs: 10, md: 16 }}>
      <Container scrollHeight={scrollHeight} ref={targetElement}>
        <StickyContainer>
          <VideoHero
            video={get(videoHero, 'fields.video')}
            fallback={get(videoHero, 'fields.videoFallback')}
            videoHq={get(videoHero, 'fields.videoHq')}
            videoMobile={get(videoHero, 'fields.videoMobile')}
            poster={get(videoHero, 'fields.poster')}
            posterMobile={get(videoHero, 'fields.posterMobile')}
          />

          <TopBorder scrollHeight={scrollHeight} ref={topRef} />
          <BottomBorder scrollHeight={scrollHeight} ref={bottomRef} />
          <LeftBorder scrollHeight={scrollHeight} ref={leftRef} />
          <RightBorder scrollHeight={scrollHeight} ref={rightRef} />

          <TextWrapper ref={textRef}>
            <Type
              as="h2"
              size={{ xs: 60, md: 120 }}
              weight="bold"
              color={textColors[textColor]}
            >
              {text}
            </Type>
          </TextWrapper>
        </StickyContainer>
      </Container>
    </Section>
  )
}

export default VideoScrollSection
