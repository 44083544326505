export const LeftChevron = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 8 14"
    height="12"
    {...props}
  >
    <path
      stroke="#52A5B8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 13L1 7l6-6"
    />
  </svg>
)
