import React, { useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'

type MultiVideoItemTextSize = 'sm' | 'md' | 'lg'

type MultiVideoItemProps = {
  videoId: string
  title?: string
  description?: string
  textOnTop?: boolean
  textSize?: MultiVideoItemTextSize
}

type WrapperProps = {
  textOnTop?: boolean
}

type TextBoxProps = {
  spacing?: number
}

const Wrapper = styled.div<WrapperProps>(({ textOnTop }) => ({
  display: 'flex',
  flex: '0 0 100%',
  flexDirection: textOnTop ? 'column-reverse' : 'column',
}))

const TextBox = styled.div<TextBoxProps>(({ theme, spacing }) => ({
  paddingTop: theme.spacing[spacing ?? 2],
  paddingBottom: theme.spacing[spacing ?? 2],
}))

const Video = styled.div({
  position: 'relative',

  '> iframe': {
    width: '100%',
  },
})

export const MultiVideoItem = ({
  videoId,
  title,
  description,
  textOnTop,
  textSize,
}: MultiVideoItemProps) => {
  const video = useRef(null)
  const player = useRef(null)

  useEffect(() => {
    const id = Number.parseInt(videoId)

    if (isNaN(id)) {
      return
    }

    player.current = new Player(video.current, {
      id,
      responsive: true,
    })
  }, [])

  const titleSizes: { [size: string]: any } = {
    sm: 'headline',
    md: 'headlineMedium',
    lg: 'headlineLarge',
  }

  const descriptionSizes: { [size: string]: any } = {
    sm: 'text',
    md: 'textLarge',
    lg: 'textExtraLarge',
  }

  return (
    <Wrapper textOnTop={textOnTop}>
      <Video ref={video} />

      <TextBox spacing={textSize === 'sm' ? 1.5 : 2}>
        <Type preset={titleSizes[textSize]} weight="bold" bottom={{ xs: 0.5 }}>
          {title}
        </Type>
        <Type preset={descriptionSizes[textSize]}>{description}</Type>
      </TextBox>
    </Wrapper>
  )
}
