import 'pure-react-carousel/dist/react-carousel.es.css'

import React, { useState } from 'react'
import { observe, useObserver, viewportWidth } from 'react-ui-observer'
import { useWindowSize } from '@react-hookz/web'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import styled, { createGlobalStyle, css } from 'styled-components'
import { mb } from 'styled-components-spacing'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { durations } from 'bl-common/src/constants/durations'
import { base } from 'bl-common/src/constants/sizes'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { Section } from 'bl-common/src/units/Section/Section'
import { between } from 'bl-common/src/utils/between'
import { media, mediaMax } from 'bl-common/src/utils/media'

import { HighlightsPlayer } from './HighlightsPlayer'
import { HighlightsThumbnail } from './HighlightsThumbnail'
import { LeftChevron } from './LeftChevron'
import { RightChevron } from './RightChevron'

type Breakpoint = 'xs' | 'md' | 'lg'
type BreakpointMap<T> = Partial<Record<Breakpoint, T>>

interface CarouselSectionProps {
  marginBottom: BreakpointMap<number>
}

const CarouselSection = styled(Section)<CarouselSectionProps>`
  ${({ marginBottom, theme }) => mb(marginBottom, theme)};
  ${({ marginBottom }) =>
    marginBottom &&
    marginBottom.xs &&
    mediaMax.md(
      css`
        margin-bottom: ${marginBottom.xs * base}px;
      `
    )};

  ${mediaMax.md(css`
    > p {
      max-width: 70%;
    }
  `)}
`

export const CarouselStyles = createGlobalStyle`
  ${media.md(css`
    .slide:first-child {
      margin-left: 0 !important;
    }
  `)}
  .slideInner div { outline-width: 0px; }

  .HighlightsCarousel {
  }

  .HighlightsCarousel-slider {
    overflow: visible;
    touch-action: pan-y;

  }

  .HighlightsCarousel-tray {
    transition: transform ${durations.short}ms;
  }

  .HighlightsCarousel-button {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: none;

    &[disabled] {
      opacity: 0;
    }

    &--next {
      right: 24px;
    }

    &--back {
      left: 24px;
    }

    > svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    ${media.md(css`
      display: block;
      top: 84px;
    `)}
  }
`

const SectionDecorator = styled.div`
  background: linear-gradient(135deg, #fdfcfb 0%, #e2e5e7 100%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 66%;
  max-width: 1500px;
`

// HighlightsCarousel takes many HighlightsCollections and
// displays their thumbnail in a carousel.
export const HighlightsCarousel = ({ highlights }) => {
  const { width } = useWindowSize()
  const NATURAL_SLIDE_HEIGHT = 112.5
  const NATURAL_SLIDE_WIDTH = 200
  const desktopSlidesThatFit = ~~(width / NATURAL_SLIDE_WIDTH)

  const [selectedCollection, setSelectedCollection] = useState(null)

  // TODO: Switch to useBreakpoints
  const isMobile = useObserver(
    observe(viewportWidth(), width => width < breakpoints.md)
  )

  return (
    <>
      <CarouselSection
        top={{ xs: 2, md: 5, lg: 7.5 }}
        bottom={{ xs: 4, md: 5, lg: 7.5 }}
        marginBottom={{ xs: 2, md: 12 }}
      >
        <Type preset="headlineMedium" as="h2" bottom={{ xs: 0.5 }}>
          Let your mind travel
        </Type>
        <Type preset="textLarge" bottom={{ xs: 2.5 }}>
          Enjoy our visual highlights from one of the wonders of the world.
        </Type>

        <CarouselStyles />

        <CarouselProvider
          naturalSlideHeight={NATURAL_SLIDE_HEIGHT}
          naturalSlideWidth={NATURAL_SLIDE_WIDTH}
          totalSlides={highlights.length}
          visibleSlides={isMobile ? 2.1 : desktopSlidesThatFit}
          isIntrinsicHeight={true}
          dragEnabled={false}
          className="HighlightsCarousel"
        >
          <Slider
            style={{
              margin: 0,
              outline: 'none',
            }}
            classNameAnimation="HighlightsCarousel-tray"
            className="HighlightsCarousel-slider"
          >
            {highlights.map((collection, index) => {
              const isFirst = index === 0
              const { collectionName, thumbnail } = collection.fields
              return (
                <Slide
                  key={collectionName}
                  index={collectionName}
                  style={{
                    width: isMobile ? `${NATURAL_SLIDE_WIDTH}px` : 'auto',
                    margin:
                      isFirst && isMobile
                        ? '0 12px 0 0'
                        : isMobile
                        ? '0 12px'
                        : `0 ${between(12, 20)}`,
                  }}
                  className="slide"
                  innerClassName="slideInner"
                >
                  <HighlightsThumbnail
                    name={collectionName}
                    image={thumbnail}
                    onClick={() => {
                      setSelectedCollection(collection)
                    }}
                  />
                </Slide>
              )
            })}
          </Slider>
          <ButtonNext className="HighlightsCarousel-button HighlightsCarousel-button--next">
            <RightChevron />
          </ButtonNext>
          <ButtonBack className="HighlightsCarousel-button HighlightsCarousel-button--back">
            <LeftChevron />
          </ButtonBack>
        </CarouselProvider>

        <SectionDecorator />
      </CarouselSection>
      {!!selectedCollection && (
        <HighlightsPlayer
          show={!!selectedCollection}
          onHide={() => setSelectedCollection(null)}
          highlights={selectedCollection?.fields.highlights}
          name={selectedCollection?.fields.collectionName}
        />
      )}
    </>
  )
}

export default HighlightsCarousel
