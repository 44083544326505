import { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ISectionHeadlineFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Section } from 'bl-common/src/units/Section/Section'

import GradientBlob from 'components/GradientBlob'

type HeadlineSectionProps = {
  section: {
    fields: ISectionHeadlineFields
  }
}

const customOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type as="h2" weight="bold" size={{ xs: 60, md: 120 }} bottom={1.5}>
        {children}
      </Type>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Type as="h2" weight="bold" preset="headlineExtraLarge" bottom={1.5}>
        {children}
      </Type>
    ),
  },
}

const HeadlineSectionWrapper = styled.div({
  position: 'relative',
})

const HeadlineSection = ({
  section: {
    fields: { headline, blobType, blobGradient, config },
  },
}: HeadlineSectionProps) => {
  return (
    <HeadlineSectionWrapper>
      <Section
        config={config}
        top={{ xs: 8, md: 14 }}
        bottom={{ xs: 10, md: 16 }}
      >
        <RichTextRenderer document={headline} customOptions={customOptions} />
      </Section>

      {!!blobType && !!blobGradient && (
        <GradientBlob
          colorOne={blobGradient?.fields?.color1}
          colorTwo={blobGradient?.fields?.color2}
          opacity={blobGradient?.fields?.opacity}
          type={blobType}
        />
      )}
    </HeadlineSectionWrapper>
  )
}

export default HeadlineSection
