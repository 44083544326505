import React from 'react'
import get from 'lodash/get'
import styled, { css } from 'styled-components'

import { formatHtmlText } from 'bl-utils/src/formatting/formatHtmlText'

import { colors } from '../../constants/colors'
import { zIndex } from '../../constants/zIndex'
import { Amount } from '../../elements/Amount'
import { Button } from '../../elements/Button/Button'
import { ContentfulImage } from '../../elements/ContentfulImage'
import { Link } from '../../elements/Link'
import { Type } from '../../elements/Typography/Typography'
import { RichTextRenderer } from '../../richText/RichTextRenderer'
import { media } from '../../utils/media'

const textAlignToFlex = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
}

type ContainerProps = {
  smallOffset?: boolean
  offsetY?: string
  offsetX?: string
}

type ImageWrapperProps = {
  horizontal?: boolean
}

type ImageProps = {
  image?: string
  layout?: string
  horizontal?: boolean
}

type CardProps = {
  backgroundColor?: any
  smallPadding?: boolean
  horizontal?: boolean
  small?: boolean
  color?: any
  style?: any
}

const documentOptions = {
  renderText: text => {
    // Replace newlines with line breaks
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

const ArrowWrap = styled(Type)`
  transition: transform 300ms;
`

const Container = styled(
  ({
    url,
    gradient,
    size,
    offsetX,
    offsetY,
    hoverAnimation,
    smallPadding,
    smallOffset,
    horizontal,
    ...rest
  }) => (url ? <Link {...rest} to={url} /> : <div {...rest} />)
)<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  z-index: ${zIndex.above};
  text-align: left;

  ${({ gradient }) =>
    gradient &&
    media.md(css`
      &::after {
        content: '';
        position: absolute;
        z-index: ${zIndex.behind};
        width: 100%;
        height: 100%;
        background: linear-gradient(
          ${gradient.degrees},
          ${gradient.color1},
          ${gradient.color2}
        );

        ${({ theme: { spacing }, smallOffset, offsetY, offsetX }: any) => {
          const offset = smallOffset ? 1.5 : 2.5
          return css`
            top: ${offsetY === 'top' ? spacing[-offset] : spacing[offset]};
            left: ${offsetX === 'left' ? spacing[-offset] : spacing[offset]};
          `
        }}
      }
    `)}

  ${({ horizontal }) =>
    horizontal &&
    media.md(css`
      flex-direction: row;
    `)}

  ${({ hoverAnimation }) =>
    hoverAnimation &&
    css`
      cursor: pointer;

      &:hover {
        ${BackgroundImage} {
          transform: scale(1.035);
        }
      }
    `}

    &[href] {
    ${media.md(css`
      &:hover {
        ${ArrowWrap} {
          transform: translateX(10px);
        }
      }
    `)}
  }
`

const Card = styled.div<CardProps>`
  color: ${colors.dark};
  flex: 1;
  background: white;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  padding: ${({ theme: { spacing }, smallPadding }) =>
    smallPadding
      ? `${spacing[2]} ${spacing[1.5]} ${spacing[2]}`
      : `${spacing[4.5]} ${spacing[2]} ${spacing[4]}`};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background: ${colors[backgroundColor]};
    `}

  .t-dark & {
    color: ${colors.white};
    background: ${colors.darkmodeOffset};
  }

  /* Use data-background because of weird bug in styled-components */
  .t-dark &[data-background='true'] {
    background: ${({ backgroundColor }) => colors[backgroundColor]};
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ButtonWrapper = styled.div`
  padding-top: ${({ theme: { spacing } }) => `${spacing[2.5]}`};
  margin-top: auto;
`

const linkStyle = css`
  color: ${colors.deepBlue};
  font-weight: 500;
`

const ImageWrapper = styled.div<ImageWrapperProps>`
  overflow: hidden;

  ${({ horizontal }) =>
    horizontal &&
    media.md(css`
      flex: 1 0 30%;
    `)}
`

const BackgroundImage = styled.div<ImageProps>`
  position: relative;
  height: 100%;
  padding-top: ${({ layout }) => (layout === 'Portrait' ? '133%' : '83%')};
  transition: transform 300ms;
  width: 100%;

  ${({ horizontal }) =>
    horizontal &&
    css`
      padding-top: 54%;
      ${media.md(css`
        padding-top: 0;
      `)}
    `}
`

const CtaLabel = styled.div`
  color: ${colors.deepBlue};
  display: flex;
  align-items: center;
  margin-top: auto;

  .t-dark & {
    color: ${colors.blueOnDark};
  }
`

export const GradientOffsetCard = ({
  image,
  imageLayout,
  heading,
  subheading,
  text,
  textContent,
  disclaimer,
  ctaLabel,
  ctaUrl,
  button,
  backgroundColor,
  color,
  gradient,
  offsetX = 'right',
  offsetY = 'bottom',
  withUpperCaseHeading = false,
  horizontalLayout = false,
  smallOffset = false,
  smallPadding = false,
  hoverAnimation = false,
  price,
  elevated = true,
  textAlign = 'left',
}) => {
  const formattedGradient = gradient
    ? {
        color1: get(gradient, 'fields.color1', gradient.color1),
        color2: get(gradient, 'fields.color2', gradient.color2),
        degrees: get(gradient, 'fields.degrees', gradient.degrees),
      }
    : null
  const formattedButton = get(button, 'fields', button)

  return (
    <Container
      gradient={formattedGradient}
      offsetY={offsetY}
      offsetX={offsetX}
      horizontal={horizontalLayout}
      smallOffset={smallOffset}
      smallPadding={smallPadding}
      hoverAnimation={hoverAnimation}
      url={ctaUrl}
    >
      {image && (
        <ImageWrapper horizontal={horizontalLayout}>
          <BackgroundImage layout={imageLayout} horizontal={horizontalLayout}>
            <ContentfulImage image={image} />
          </BackgroundImage>
        </ImageWrapper>
      )}
      <Card
        horizontal={horizontalLayout}
        small={smallOffset}
        backgroundColor={backgroundColor}
        smallPadding={smallPadding}
        color={color}
        style={
          elevated
            ? { textAlign }
            : { boxShadow: 'none', paddingLeft: 0, paddingRight: 0, textAlign }
        }
        data-background={!!backgroundColor} // Hack to fix bug in styled components
      >
        <Content>
          <Type
            preset="headlineSmall"
            bottom={{
              xs: 0.5,
              md: 0.5,
            }}
            case={withUpperCaseHeading && 'uppercase'}
          >
            {heading}
          </Type>
          {subheading || price ? (
            price ? (
              <Type opacity={0.75} bottom={{ xs: 0.5, md: 1.5 }} preset="text">
                <Amount value={price} format={price?.fields?.priceFormat} />
              </Type>
            ) : (
              <Type opacity={0.75} bottom={{ xs: 0.5, md: 1.5 }} preset="text">
                {subheading}
              </Type>
            )
          ) : null}
          {
            // Prefer rich text(textContent) over deprecated text field.
            textContent ? (
              <RichTextRenderer
                document={textContent}
                customOptions={documentOptions}
              />
            ) : text ? (
              <Type
                preset="text"
                multiline
                dangerouslySetInnerHTML={{
                  __html: formatHtmlText(text, linkStyle),
                }}
                bottom={1.5}
              />
            ) : null
          }
          {disclaimer && (
            <Type case="uppercase" bottom={1.5} preset="textSmall">
              {disclaimer}
            </Type>
          )}
          {ctaLabel && (
            <CtaLabel style={{ justifyContent: textAlignToFlex[textAlign] }}>
              <Type weight="bold" right={{ xs: 0.5 }} preset="text">
                {ctaLabel}
              </Type>
              <ArrowWrap
                preset="text"
                weight="bold"
                style={{ paddingBottom: 3 }}
              >
                →
              </ArrowWrap>
            </CtaLabel>
          )}
          {formattedButton && (
            <ButtonWrapper>
              <Button {...formattedButton} paddingSize="small">
                {formattedButton.text}
              </Button>
            </ButtonWrapper>
          )}
        </Content>
      </Card>
    </Container>
  )
}
