import React, {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { useMutationObserver } from 'rooks'

import { useBreakpoints } from '../../hooks/useBreakpoints'
import * as styles from './styles'
import { SubnavigationItem, SubnavigationItemProps } from './SubnavigationItem'

interface SubnavigationComposition {
  Item: FC<SubnavigationItemProps>
}

interface SubnavigationProps {
  children?: ReactNode
  containerSelector?: string
}

declare type Anchor = {
  top: number
  bottom: number
  anchor: string
}

const Subnavigation: FC<SubnavigationProps> & SubnavigationComposition = ({
  children,
  containerSelector = '[data-section-container]',
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [showStart, setShowStart] = useState(false)
  const [showEnd, setShowEnd] = useState(false)
  const containerRef = useRef(null)
  const triggers = useRef<Array<Anchor>>([])
  const rootRef = useRef(null)
  const { mediaMlg } = useBreakpoints()
  const [ignore, setIgnore] = useState(false)
  const [isInteracting, setIsInteracting] = useState(false)

  const navigationItems = React.Children.toArray(children).filter(child => {
    const item = child as ReactElement<
      PropsWithChildren<SubnavigationItemProps>
    >
    return !item.props.isButton
  }) as ReactElement<PropsWithChildren<SubnavigationItemProps>>[]

  const buttons = React.Children.toArray(children).filter(child => {
    const item = child as ReactElement<
      PropsWithChildren<SubnavigationItemProps>
    >
    return item.props.isButton
  })

  const itemRefs = navigationItems.map(() => useRef(null))

  // every time scrolling stops we'll reset the ignore flag
  const checkScrollStop = useCallback(
    debounce(() => setIgnore(false), 50),
    [setIgnore]
  )

  useEffect(() => {
    window.addEventListener('scroll', checkScrollStop)

    return () => window.removeEventListener('scroll', checkScrollStop)
  }, [])

  const updateTriggers = useCallback(() => {
    if (!triggers?.current) return
    const anchorTags = navigationItems.reduce<Array<Anchor>>((arr, curr) => {
      if (!React.isValidElement(curr)) return arr
      const anchorTag = curr.props.anchorTag

      if (!anchorTag) return arr

      const element = document.getElementById(anchorTag.replace('#', ''))
      const rect = element?.getBoundingClientRect()

      if (!rect) return arr

      return [
        ...arr,
        {
          top: element.offsetTop,
          bottom: element.offsetTop + rect.height,
          anchor: anchorTag,
        },
      ]
    }, [])

    triggers.current = anchorTags
  }, [navigationItems, buttons])

  useMutationObserver(rootRef, updateTriggers, {
    childList: true,
  })

  useEffect(() => {
    if (containerRef?.current) {
      rootRef.current = document.querySelector(containerSelector)
    }
    updateTriggers()
    const onScroll = throttle(() => {
      const {
        scrollWidth = 0,
        scrollLeft = 0,
        offsetWidth = 0,
      } = containerRef.current || {}
      setShowStart(scrollLeft > 0)
      setShowEnd(scrollLeft + offsetWidth < scrollWidth)
    }, 250)

    function handle() {
      requestAnimationFrame(onScroll)
    }

    if (containerRef?.current) {
      containerRef.current.addEventListener('scroll', handle)
    }
    return () => {
      if (containerRef?.current) {
        containerRef.current.removeEventListener('scroll', handle)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window === 'undefined') return

    const handle = throttle(() => {
      // If we are scrolling to section after clicking on a link, ignore the scroll event
      if (ignore) return
      const activeSection = triggers?.current.findIndex(
        trigger =>
          window.scrollY >= trigger.top - 10 &&
          window.scrollY < trigger.bottom - 30
      )

      setSelectedIndex(activeSection)
    }, 500)

    window.addEventListener('scroll', handle)

    return () => window.removeEventListener('scroll', handle)
  }, [triggers, ignore])

  const handleKeyPress = itemIndex => event => {
    const itemCount = itemRefs.length
    let next = null

    if (event.key === 'ArrowLeft') {
      next = highlightedIndex - 1 < 0 ? itemCount - 1 : highlightedIndex - 1
    }
    if (event.key === 'ArrowRight') {
      next = highlightedIndex + 1 > itemCount - 1 ? 0 : highlightedIndex + 1
    }
    if (event.key === 'Home') {
      next = 0
    }
    if (event.key === 'End') {
      next = itemCount - 1
    }
    if (event.key === 'Enter') {
      next = itemIndex
      setSelectedIndex(next) // this will trigger the scroll
      handleSubnavInteraction()
    }

    if (next !== null) {
      setHighlightedIndex(next)
      itemRefs[next]?.current?.focus()
    }
  }

  const handleSubnavInteraction = useCallback(() => {
    setIsInteracting(true)
    setTimeout(() => setIsInteracting(false), 500)
  }, [])

  // Scroll to the selected section
  useEffect(() => {
    setHighlightedIndex(selectedIndex)
    const currentTrigger = triggers?.current[selectedIndex]

    if (currentTrigger) {
      const element = document.getElementById(
        currentTrigger.anchor.replace('#', '')
      )

      if (element) {
        setIgnore(true)
        if (isInteracting) element?.scrollIntoView()
      }
    }
  }, [selectedIndex, triggers])

  return (
    <styles.container showEnd={showEnd} showStart={showStart}>
      <styles.nav aria-label="page navigation" ref={containerRef}>
        <styles.list
          role="menubar"
          aria-label="page subnavigation"
          aria-orientation="horizontal"
        >
          {navigationItems.map((item, i) => {
            if (!React.isValidElement(item)) return

            return React.cloneElement(item, {
              selected: i === selectedIndex,
              highlighted: i === highlightedIndex,
              onClick: () => setSelectedIndex(i),
              itemRef: itemRefs[i],
              onKeyPress: handleKeyPress(i),
              onMouseDown: () => handleSubnavInteraction(),
              onTouchStart: () => handleSubnavInteraction(),
            })
          })}
        </styles.list>
        {mediaMlg && <styles.buttons>{buttons}</styles.buttons>}
      </styles.nav>
    </styles.container>
  )
}

Subnavigation.Item = SubnavigationItem

export { Subnavigation }
