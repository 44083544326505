import React, { ReactNode } from 'react'

type BrowserOnlyProps = {
  onSSR?: ReactNode
  children?: ReactNode
}

type BrowserOnlyState = {
  canRender?: boolean
}

const DefaultOnSSR = () => <span />

export class BrowserOnly extends React.Component<
  BrowserOnlyProps,
  BrowserOnlyState
> {
  constructor(args) {
    super(args)
    this.state = {
      canRender: false,
    }
  }

  componentDidMount() {
    this.setState({ canRender: true })
  }

  render() {
    const { children, onSSR = <DefaultOnSSR /> } = this.props
    const { canRender } = this.state

    return canRender ? children : onSSR
  }
}

export default BrowserOnly
