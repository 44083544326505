import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import {
  gridOffsetFrom,
  gridOffsetTo,
  modularScale,
} from '../../constants/sizes'
import { Button as _Button } from '../../elements/Button/Button'
import { Type } from '../../elements/Typography/Typography'
import { between } from '../../utils/between'
import { media, mediaMax } from '../../utils/media'

type ContainerProps = {
  isMobile?: boolean
}

const Container = styled.div<ContainerProps>`
  background: ${colors.white};
  color: ${colors.dark};
  padding: 32px;
  align-self: flex-end;
  display: none;

  ${({ isMobile }) =>
    isMobile &&
    mediaMax.md(
      css`
        display: block;
        margin-top: 64px;
        margin-left: ${gridOffsetFrom}px;
        margin-right: ${gridOffsetFrom}px;
      `
    )};

  ${({ isMobile }) =>
    !isMobile &&
    media.md(
      css`
        width: 100%;
        display: block;
      `
    )};

  ${media.md(css`
    margin-bottom: ${between(gridOffsetFrom, gridOffsetTo)};
    padding: ${({ theme }) =>
      `${theme.spacing[4.5]} ${theme.spacing[3.5]} ${theme.spacing[4]} ${theme.spacing[4]}`};
    width: ${between(520 / modularScale, 520)};
  `)};
`

const Button = styled(({ paddingSize, size, ...rest }) => (
  <_Button paddingSize="large" size={{ xs: 14, md: 14 }} {...rest} />
))`
  display: inline-flex;
  ${mediaMax.md(css`
    width: 100%;
  `)};
`

export const HeroOfferCard = ({ title, text, button, isMobile = false }) => (
  <Container isMobile={isMobile}>
    <Type preset="headline" bottom={{ xs: 0.5, md: 1 }}>
      {title}
    </Type>
    <Type preset="textLarge" bottom={{ xs: 2, md: 4 }}>
      {text}
    </Type>
    {button && (
      <Button
        key={button.id}
        paddingSize={button.paddingSize}
        preset={button.preset}
        to={button.link}
        emailTrigger={button.emailTrigger}
      >
        {button.text}
      </Button>
    )}
  </Container>
)
