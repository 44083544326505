import { ReactNode } from 'react'

import { renderComponent } from '../utils/renderComponent'

type childrenFn = (value: unknown, index: number, array: unknown[]) => ReactNode

type RenderComponentProps = {
  componentData?: any
  contentType?: string
  children?: childrenFn
  many?: boolean
  limit?: number
}

// Must be of type any until this issue is resolved: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
// Refactoring of components that use this compoennts can also solve the issue
export const RenderComponent = ({
  componentData,
  contentType = '',
  children: render,
  many,
  limit,
}: RenderComponentProps): any =>
  renderComponent(componentData, { contentType, many, limit }, render)
