import React from 'react'

import { Quote } from 'bl-common/src/units/Quote'
import { Section } from 'bl-common/src/units/Section/Section'

import { getSectionProps } from '../../../utils/getSectionProps'

const QuoteSection = props => {
  const { config, quote, source } = getSectionProps(props)

  return (
    <Section
      bottom={{ xs: 1.5, md: 2.5 }}
      top={{ xs: 1.5, md: 2.5 }}
      config={config}
    >
      <Quote quote={quote} source={source} />
    </Section>
  )
}

export default QuoteSection
