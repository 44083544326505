import { useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import styled from 'styled-components'

import { breakpoints } from 'bl-common/src/constants/breakpoints'
import { gridOffsetFrom } from 'bl-common/src/constants/sizes'
import { ISectionParallelCardsFields } from 'bl-common/src/generated/contentful'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Gradient } from 'bl-common/src/types/custom'
import { ClientOnlyModal } from 'bl-common/src/units/ClientOnlyModal'
import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'
import { getImageUrl } from 'bl-common/src/units/SmartImage/getImageUrl'
import { mediaObj } from 'bl-common/src/utils/media'

import GradientBlob from 'components/GradientBlob'
import { formatGradient } from 'utils/formatters'

import { ModalCard } from './ModalCard'

type ParallelCardsSectionProps = {
  section: {
    fields: ISectionParallelCardsFields
  }
}

type CardWrapperProps = {
  small?: boolean
  switchParallax?: boolean
}

const ParallelCardsSectionWrapper = styled.div({
  position: 'relative',
})

const CardsContainer = styled.div<{ background?: Gradient }>(
  ({ theme, background }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing[3],
    alignItems: 'center',
    position: 'relative',
    paddingRight: gridOffsetFrom,
    paddingLeft: gridOffsetFrom,

    ...(background && {
      '::after': {
        content: '""',
        position: 'absolute',
        inset: `${theme.spacing[8]} 0 ${theme.spacing[8]} 0`,
        background: `linear-gradient(
        ${background.degrees},
        ${background.color1},
        ${background.color2}
        )`,
        zIndex: -1,
      },
    }),

    [mediaObj.md]: {
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing[4],
      paddingLeft: theme.spacing[4],
      paddingRight: theme.spacing[4],

      ...(background && {
        '::after': {
          inset: 0,
        },
      }),
    },

    [mediaObj.lg]: {
      gap: theme.spacing[3],
      paddingLeft: theme.spacing[5],
      paddingRight: theme.spacing[5],
    },
  })
)

const CardWrapper = styled.div<CardWrapperProps>(
  ({ theme, small, switchParallax }) => ({
    width: small ? '85%' : '100%',
    maxWidth: small ? 380 : 420,
    justifySelf: 'center',

    [`@media (min-width: ${breakpoints.md}px)`]: {
      width: small ? '75%' : '90%',
      maxWidth: small ? 380 : 480,

      ':nth-child(1)': {
        transform: `translateY(${
          theme.spacing[switchParallax ? 8 : -8]
        }) translateY(calc( var(--parallax) * ${
          theme.spacing[switchParallax ? -4 : 4]
        } ))`,
      },

      ':nth-child(2)': {
        transform: `translateY(${
          theme.spacing[switchParallax ? -8 : 4]
        }) translateY(calc( var(--parallax) * ${
          theme.spacing[switchParallax ? 4 : -4]
        } ))`,
      },
    },

    [`@media (min-width: ${breakpoints.lg}px)`]: {
      width: small ? '65%' : '90%',
      maxWidth: 'unset',
    },
  })
)

const ModalContent = styled.div(({ theme }) => ({
  padding: theme.spacing[4],
}))

const ParallelCardsSection = ({
  section: {
    fields: {
      cardLayout,
      cards,
      roundedCardCorners,
      blobGradient,
      backgroundGradient,
      config,
    },
  },
}: ParallelCardsSectionProps) => {
  const [modalContent, setModalContent] = useState<Document>(undefined)
  const formattedGradient = formatGradient(backgroundGradient)

  const isSmall = (index: number) => {
    switch (cardLayout) {
      case 'DuoNormal':
        return false
      case 'DuoLeftSmall':
        return index === 0
      case 'DuoRightSmall':
        return index === 1
      default:
        return false
    }
  }

  return (
    <ParallelCardsSectionWrapper>
      <OffsetSection
        config={config}
        top={{ xs: 8, md: 14 }}
        bottom={{ xs: 10, md: 16 }}
        offsetDirection="both"
        fullWidthMobile
      >
        <CardsContainer background={formattedGradient}>
          {cards?.map((card, index) => (
            <CardWrapper
              key={index}
              small={isSmall(index)}
              switchParallax={cardLayout === 'DuoLeftSmall'}
            >
              <ResponsiveParallax
                key={index}
                disabledMobile
                from={
                  (index === 0 ? -5 : 3) *
                  (cardLayout === 'DuoLeftSmall' ? -1 : 1)
                }
                to={
                  (index === 0 ? 3 : -3) *
                  (cardLayout === 'DuoLeftSmall' ? -1 : 1)
                }
              >
                <ModalCard
                  imageUrl={getImageUrl(card?.fields.image, {
                    width: 800,
                    ssr: true,
                  })}
                  title={card?.fields.title}
                  description={card?.fields.description}
                  rounded={roundedCardCorners}
                  small={isSmall(index)}
                  ctaLabel={card?.fields?.ctaLabel}
                  ctaDisplayAsButton={card?.fields?.ctaDisplayAsButton}
                  ctaLinkTo={
                    card?.fields?.link
                      ? card?.fields?.link?.fields?.url
                      : undefined
                  }
                  ctaOnClick={
                    card?.fields?.modalContent
                      ? () => setModalContent(card?.fields?.modalContent)
                      : undefined
                  }
                />
              </ResponsiveParallax>
            </CardWrapper>
          ))}
        </CardsContainer>
      </OffsetSection>

      <ClientOnlyModal
        show={!!modalContent}
        onHide={() => setModalContent(undefined)}
      >
        <ModalContent>
          <RichTextRenderer document={modalContent} />
        </ModalContent>
      </ClientOnlyModal>

      {!!blobGradient && (
        <GradientBlob
          colorOne={blobGradient?.fields?.color1}
          colorTwo={blobGradient?.fields?.color2}
          opacity={blobGradient?.fields?.opacity}
          type="two"
        />
      )}
    </ParallelCardsSectionWrapper>
  )
}

export default ParallelCardsSection
