import React from 'react'
import get from 'lodash/get'

import { Section } from 'bl-common/src/units/Section/Section'

import { formatGradient } from '../../../utils/formatters'
import { getSectionProps } from '../../../utils/getSectionProps'
import { SplitContent } from '../../SplitContent'

const SplitContentSection = props => {
  const { splitContent, config } = getSectionProps(props)

  return (
    <Section bottom={{ xs: 2, md: 6 }} top={{ xs: 2, md: 6 }} config={config}>
      <SplitContent
        images={get(splitContent, 'fields.images')}
        textContentSide={get(splitContent, 'fields.textContentSide')}
        gradient={formatGradient(
          get(splitContent, 'fields.imageGradientBackground')
        )}
        content={get(splitContent, 'fields.content')}
        imageIsTall={get(splitContent, 'fields.imageIsTall')}
        videoId={get(splitContent, 'fields.videoId')}
        videoWithControls={get(splitContent, 'fields.videoWithControls')}
      />
    </Section>
  )
}

export default SplitContentSection
