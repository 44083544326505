import { ReactNode } from 'react'

export const renderComponent = (
  componentData: {
    sys: { type: string; contentType?: { sys: { id: string } } }
  }[],
  {
    contentType,
    many,
    limit,
  }: { contentType?: string; many: boolean; limit?: number },
  render: (value: any, index: number, array: any[]) => ReactNode | null
): ReactNode | null => {
  if (typeof render !== 'function' || !componentData) {
    return null
  }

  if (!Array.isArray(componentData)) {
    componentData = [componentData]
  }

  const count = many ? limit : 1
  const notLink = (obj: any) => obj.sys.type !== 'Link'
  const matchesContentType = (obj: any) =>
    obj.sys.contentType && obj.sys.contentType.sys.id === contentType

  const filter = contentType
    ? (obj: any) => obj && notLink(obj) && matchesContentType(obj)
    : (obj: any) => notLink(obj)

  const elements = componentData.filter(filter).slice(0, count).map(render)

  return count === 1 ? elements[0] || null : elements
}
