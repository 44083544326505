import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { modularScale } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { Button } from '../../elements/Button/Button'
import { Link } from '../../elements/Link'
import { between } from '../../utils/between'
import { media, mediaMax, mediaMaxObj } from '../../utils/media'
import { mixins } from '../../utils/mixins'

interface ItemProps {
  selected: boolean
}
interface ContainerProps {
  showStart: boolean
  showEnd: boolean
}

export const container = styled.div<ContainerProps>`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    opacity: 0;
    transition: opacity 0.3 ease-in-out;
    pointer-events: none;

    ${media.md(
      css`
        display: none;
      `
    )}
  }

  ::before {
    left: -1px; // avoid gradient overlap
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    z-index: ${zIndex.menu};
  }

  ::after {
    right: -1px; // avoid gradient overlap
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  .t-dark & {
    ::before {
      background: linear-gradient(
        to left,
        rgba(51, 51, 51, 0),
        rgba(51, 51, 51, 1)
      );
    }

    ::after {
      background: linear-gradient(
        to right,
        rgba(51, 51, 51, 0),
        rgba(51, 51, 51, 1)
      );
    }
  }

  ${({ showStart }) =>
    showStart &&
    css`
      ::before {
        opacity: 1;
      }
    `}
  ${({ showEnd }) =>
    showEnd &&
    css`
      ::after {
        opacity: 1;
      }
    `}
`

export const nav = styled.nav`
  background-color: ${colors.white};
  display: flex;
  height: 80px;

  ${mixins.disableScrollbarOnMobile()};
  ${mediaMax.md(
    css`
      height: 58px;
      overflow: auto;
    `
  )};

  .t-dark & {
    background-color: ${colors.darkmodeOffset};
  }
`
export const list = styled.ul`
  align-items: stretch;
  column-gap: ${({ theme }) => theme.spacing[2.5]};
  display: flex;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;

  ${media.lg(css`
    column-gap: ${({ theme }) => theme.spacing[4]};
  `)};
`

export const item = styled.li<ItemProps>`
  --item-color: ${rgba(colors.black, 0.75)};
  --item-color-selected: ${colors.black};
  --item-opacity: 0.75;

  .t-dark & {
    --item-color: ${colors.white};
    --item-color-selected: ${colors.blueOnDark};
    --item-opacity: 1;
  }

  color: ${({ selected }) =>
    selected ? 'var(--item-color-selected)' : 'var(--item-color)'};

  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;

  ${media.md(css`
    font-size: 16px;
  `)};

  .t-dark & {
    background-color: ${colors.darkmodeOffset};
  }

  ::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 2px;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translate3d(-100%, 0, 0);
    display: block;
    background: #52a5b8;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    .t-dark & {
      background: ${colors.blueOnDark};
    }
  }

  &:active {
    outline: 0;
  }

  &:hover,
  &:focus-within {
    opacity: 1;
    color: var(--item-color-selected);
  }

  ${({ selected = false }) =>
    selected &&
    css`
      opacity: 1;
      ::after {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
      }
    `}
`

const linkStyles = css`
  color: inherit;
  font-weight: 500;
  font-size: 16px;

  ::after {
    content: '';
    position: absolute;
    inset: 0 -20px;
    z-index: 1;
    cursor: pointer;
  }
`

export const link = styled(Link)`
  ${linkStyles};
`

export const anchor = styled.button`
  ${linkStyles};
`
export const buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  margin-left: ${({ theme }) => theme.spacing[2]};
`
export const _Button = styled(Button)({
  padding: `${between(12 / modularScale, 12)} ${between(
    40 / modularScale,
    40
  )}`,

  '> p': {
    fontSize: 16,
  },

  [mediaMaxObj.md]: {
    padding: '12px 40px',
  },
})
