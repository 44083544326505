import styled, { css } from 'styled-components'
import { pb, pt } from 'styled-components-spacing'

import { colors } from '../../constants/colors'
import { base } from '../../constants/sizes'
import { zIndex } from '../../constants/zIndex'
import { mediaMax } from '../../utils/media'

type SectionContentProps = {
  textColor?: string
  top?: any
  bottom?: any
}

export const SectionContent = styled.div<SectionContentProps>`
  position: relative;
  color: ${props =>
    String(props.textColor).startsWith('#')
      ? props.textColor
      : String(props.textColor).toLowerCase() === 'light'
      ? colors.white
      : 'inherit'};

  ${({ top, theme }) => pt(top, theme)};

  ${({ top }) =>
    top &&
    top.xs &&
    mediaMax.md(
      css`
        padding-top: ${top.xs * base}px;
      `
    )};

  ${({ bottom, theme }) => pb(bottom, theme)};

  ${({ bottom }) =>
    bottom &&
    bottom.xs &&
    mediaMax.md(
      css`
        padding-bottom: ${bottom.xs * base}px;
      `
    )};

  z-index: ${zIndex.above};
`
