import React from 'react'
import styled, { css } from 'styled-components'

import { ResponsiveParallax } from 'bl-common/src/units/ResponsiveParallax'
import { media } from 'bl-common/src/utils/media'

import { BlobFrown, BlobSmile } from './Blobs'

// NOTE: The reason why this looks hacky is because we are making overflow-y visible while making overflow-x hidden on section.
// That is literally impossible with current css if you want to keep position sticky available.
// So in order to see the overflowY we are creating an absolute very high wrapper to contain the blob.
const BlobWrapper = styled.div`
  height: 150%;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: -15%;
  z-index: -1;
`

const BlobContainer = styled.div`
  position: absolute;
  z-index: -1;
  transform: translateX(-50%) translateY(-50%);
`

const BlobOneContainer = styled(BlobContainer)`
  top: 50%;
  left: 80%;
  width: 100%;

  ${media.md(css`
    width: 80%;
  `)}
`

const BlobTwoContainer = styled(BlobContainer)`
  top: 40%;
  left: 60%;
  width: 80%;

  ${media.md(css`
    width: 60%;
  `)}
`

const BlobThreeContainer = styled(BlobContainer)`
  top: 60%;
  left: 0;
  width: 120%;

  ${media.md(css`
    width: 100%;
  `)}
`

interface GradientBlobProps {
  colorOne: string
  colorTwo: string
  type?: 'one' | 'two' | 'three'
  opacity?: number
}

const GradientBlob = ({
  colorOne,
  colorTwo,
  type,
  opacity = 0.5,
}: GradientBlobProps) => {
  const renderBlob = () => {
    switch (type) {
      case 'one':
        return (
          <BlobOneContainer>
            <ResponsiveParallax from={5} to={-8}>
              <BlobFrown
                colorOne={colorOne}
                colorTwo={colorTwo}
                opacity={opacity}
                blur={40}
              />
            </ResponsiveParallax>
          </BlobOneContainer>
        )
      case 'two':
        return (
          <BlobTwoContainer>
            <ResponsiveParallax from={10} to={-10}>
              <BlobSmile
                colorOne={colorOne}
                colorTwo={colorTwo}
                opacity={opacity}
                blur={40}
              />
            </ResponsiveParallax>
          </BlobTwoContainer>
        )
      case 'three':
        return (
          <BlobThreeContainer>
            <ResponsiveParallax from={5} to={-10}>
              <BlobFrown
                colorOne={colorOne}
                colorTwo={colorTwo}
                opacity={opacity}
                blur={40}
              />
            </ResponsiveParallax>
          </BlobThreeContainer>
        )
      default:
        return <div />
    }
  }

  return <BlobWrapper>{renderBlob()}</BlobWrapper>
}

export default GradientBlob
